<template>
	<div
		class="data-list-container main-list-view">
		<vs-navbar class="main-toolbar" type="flat" color="#4690DE">
			<div slot="title">
				<vs-navbar-title>
					<div class="logo-top-left">
						<router-link to="/">
							<img src="@/assets/images/logo/koblatil.png" alt="Kobla" class="pt-3 pl-3"/>
						</router-link>
					</div>
				</vs-navbar-title>
			</div>
			<vs-navbar-item v-if="!userProfile" class="pl-10">
				<div class="text-register">
					<router-link to="/register">{{ $t('Register') }}</router-link>
				</div>
			</vs-navbar-item>
			<vs-navbar-item  v-if="!userProfile" class="pl-4">
				<vs-button @click="login" color="#4690DE" class="mr-4 btn-login-upcoming">{{$t('Login')}}</vs-button>
			</vs-navbar-item>
		</vs-navbar>

	<vs-row vs-justify="center" class="mt-12 mb-base">
		<vs-col type="flex" vs-justify="center" vs-align="center" class="ml-2 mr-2 xs:w-1/1 sm:w-3/4 lg:w-1/3">
		<div class="container">
			<div class="mt-10 mb-5">
				<h3 class="mb-10">REGULATORY ENFORCEMENT</h3>
				<h1>Terms &amp; Conditions</h1>
			</div>

			<div class="mt-12">
				<h5 class="mb-3">1. Terms</h5>
				By accessing the website at Koblatil.no, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.
			</div>

			<div class="mt-12">
				<h5 class="mb-3">2. Use License</h5>
				Permission is granted to temporarily download one copy of the materials (information or software) on Koblatil's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
				<br><br>
				modify or copy the materials;
				<br><br>
				use the materials for any commercial purpose, or for any public display (commercial or non-commercial);
				attempt to decompile or reverse engineer any software contained on Koblatil's website;
				remove any copyright or other proprietary notations from the materials; or
				transfer the materials to another person or "mirror" the materials on any other server.
				This license shall automatically terminate if you violate any of these restrictions and may be terminated by Koblatil at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
			</div>
			<div class="mt-12">
				<h5 class="mb-3">3. Disclaimer</h5>
				The materials on Koblatil's website are provided on an 'as is' basis. Koblatil makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
				<br><br>
				Further, Koblatil does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.
			</div>
			<div class="mt-12">
				<h5 class="mb-3">4. Limitations</h5>
				In no event shall Koblatil or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Koblatil's website, even if Koblatil or a Koblatil authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
			</div>
			<div class="mt-12">
				<h5 class="mb-3">5. Accuracy of materials</h5>
				The materials appearing on Koblatil's website could include technical, typographical, or photographic errors. Koblatil does not warrant that any of the materials on its website are accurate, complete or current. Koblatil may make changes to the materials contained on its website at any time without notice. However Koblatil does not make any commitment to update the materials.
			</div>
			<div class="mt-12">
				<h5 class="mb-3">6. Links</h5>
				Koblatil has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Koblatil of the site. Use of any such linked website is at the user's own risk.
			</div>
			<div class="mt-12">
				<h5 class="mb-3">7. Modifications</h5>
				Koblatil may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.
			</div>
			<div class="mt-12">
				<h5 class="mb-3">8. Governing Law</h5>
				These terms and conditions are governed by and construed in accordance with the laws of Norway and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
			</div>
		</div>
		</vs-col>
	</vs-row>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isLoggingIn: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
    userProfile() {
      const profile = this.user ? this.user : null;
      return profile;
    },
  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      // if (this.$store.state.auth.isUserLoggedIn()) {
      if (this.$store.state.isLoggedIn) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
        });

        return false;
      }
      return true;
    },
    login() {
      this.$router.push('/login').catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.event-user{
	margin-left: auto;
	margin-right: auto;
		max-width: 681px;
}
.main-toolbar{
	background-color: #F8F8F8!important;
}
.logo-top-left{
	width: 174.18px;
	height: 65.75px;
}
.text-register{
	font-size: 18px!important;
	color: #4690DE;
}
</style>
